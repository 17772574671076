<template>
  <StatStationComponent />
</template>

<script>
import StatStationComponent from '../../components/stat/StatStation'

export default {
  name: 'StatStation',
  components: {
    StatStationComponent,
  }
}
</script>