<template>
  <v-container>
    <DateFilters
        @update="loadData"
        ref="dateFilters"
    />

    <v-sheet
        class="pa-3"
        color="grey lighten-3"
        height="430"
    >
      <bar-chart
          v-if="loaded"
          :chartdata="dataCounts"
          :options="optionsCounts"
      />
    </v-sheet>

    <v-sheet
        class="pa-3 mt-12"
        color="grey lighten-3"
        height="430"
    >
      <bar-chart
          v-if="loaded"
          :chartdata="dataVolume"
          :options="optionsVolume"
      />
    </v-sheet>

    <v-sheet
        class="pa-3 mt-12"
        color="grey lighten-3"
        height="430"
    >
      <bar-chart
          v-if="loaded"
          :chartdata="dataVolumeTotal"
          :options="optionsVolumeTotal"
      />
    </v-sheet>

    <v-sheet
        class="pa-3 mt-12"
        color="grey lighten-3"
        height="430"
    >
      <bar-chart
          v-if="loaded"
          :chartdata="dataTotalCost"
          :options="optionsTotalCost"
      />
    </v-sheet>

    <v-sheet
        class="pa-3 mt-12"
        color="grey lighten-3"
        height="430"
    >
      <bar-chart
          v-if="loaded"
          :chartdata="dataTotalCostTotal"
          :options="optionsTotalCostTotal"
      />
    </v-sheet>
  </v-container>
</template>

<script>
import statApi from '../../api/stat.js'
import stationApi from '../../api/station.js'
import BarChart from "./BarChart.vue"
import DateFilters from "../utils/DateFilters"
import moment from 'moment'

export default {
  name: 'StatStationComponent',
  components: { BarChart, DateFilters },
  data: () => {
    let baseOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [{
          ticks: {
            suggestedMin: 0,
          }
        }]
      },
    }

    return {
      loaded: false,
      dataCounts: null,
      dataVolumeTotal: null,
      dataVolume: null,
      dataTotalCostTotal: null,
      dataTotalCost: null,
      optionsCounts: Object.assign({
        title: {
          display: true,
          text: 'Сколько раз заправлено',
        }
      }, baseOptions),
      optionsVolumeTotal: Object.assign({
        title: {
          display: true,
          text: 'Объем топлива (всего)',
        }
      }, baseOptions),
      optionsVolume: Object.assign({
        title: {
          display: true,
          text: 'Объем топлива (агрегированно)',
        }
      }, baseOptions),
      optionsTotalCostTotal: Object.assign({
        title: {
          display: true,
          text: 'Потрачено денег (всего)',
        }
      }, baseOptions),
      optionsTotalCost: Object.assign({
        title: {
          display: true,
          text: 'Потрачено денег (агрегированно)',
        }
      }, baseOptions),
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    loadData () {
      this.loaded = false
      let
          dateFromIso = null,
          dateToIso = null

      if (this.$refs.dateFilters.dateFrom) {
        dateFromIso = moment(this.$refs.dateFilters.dateFrom).format('YYYY-MM-DDT00:00:00Z')
      }

      if (this.$refs.dateFilters.dateTo) {
        dateToIso = moment(this.$refs.dateFilters.dateTo).format('YYYY-MM-DDT23:59:59Z')
      }

      let stationsPromise = stationApi.getAll();

      statApi.getStations(dateFromIso, dateToIso).then((data) => {
        stationsPromise.then((stationsData) => {
          let
              stationsById = {},
              labels = [],
              datasetCounts = [],
              datasetVolumeTotal = [],
              datasetVolumeMax = [],
              datasetVolumeMin = [],
              datasetVolumeAvg = [],
              datasetTotalCostTotal = [],
              datasetTotalCostMax = [],
              datasetTotalCostMin = [],
              datasetTotalCostAvg = []

          stationsData.data.items.forEach(item => stationsById[item.id] = item.name)

          data.data.stat.forEach(item => {
            labels.push(item.station === null ? 'Не указано' : stationsById[item.station]);
            datasetCounts.push(item.count)

            datasetVolumeTotal.push(item.sumVolume / 100)
            datasetVolumeMax.push(item.maxVolume / 100)
            datasetVolumeMin.push(item.minVolume / 100)
            datasetVolumeAvg.push(item.avgVolume / 100)

            datasetTotalCostTotal.push(item.sumTotalCost / 100)
            datasetTotalCostMax.push(item.maxTotalCost / 100)
            datasetTotalCostMin.push(item.minTotalCost / 100)
            datasetTotalCostAvg.push(item.avgTotalCost / 100)
          })

          this.dataCounts = {
            labels: labels,
            datasets: [
              {
                label: 'Количество',
                backgroundColor: '#f87979',
                data: datasetCounts,
              }
            ]
          }

          this.dataVolumeTotal = {
            labels: labels,
            datasets: [
              {
                label: 'Всего',
                backgroundColor: '#f87979',
                data: datasetVolumeTotal,
              },
            ]
          }

          this.dataVolume = {
            labels: labels,
            datasets: [
              {
                label: 'Максимум',
                backgroundColor: '#48e010',
                data: datasetVolumeMax,
              }, {
                label: 'Минимум',
                backgroundColor: '#7995f8',
                data: datasetVolumeMin,
              }, {
                label: 'Среднее',
                backgroundColor: '#a121d0',
                data: datasetVolumeAvg,
              },
            ]
          }

          this.dataTotalCost = {
            labels: labels,
            datasets: [
              {
                label: 'Максимум',
                backgroundColor: '#48e010',
                data: datasetTotalCostMax,
              }, {
                label: 'Минимум',
                backgroundColor: '#7995f8',
                data: datasetTotalCostMin,
              }, {
                label: 'Среднее',
                backgroundColor: '#a121d0',
                data: datasetTotalCostAvg,
              },
            ]
          }

          this.dataTotalCostTotal = {
            labels: labels,
            datasets: [
              {
                label: 'Всего',
                backgroundColor: '#f87979',
                data: datasetTotalCostTotal,
              },
            ]
          }

          this.loaded = true
        })
      })
    },
  },
}
</script>