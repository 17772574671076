import {httpClient, getSort} from './httpClient';
import QueryString from 'qs';

const crudFactory = (endPoint) => {
    const getAll = () => {
        return getItems([], [], 1, -1)
    };

    const getItems = (sortBy, sortDesc, page, itemsPerPage) => {
        let params = {
            offset: (page - 1) * itemsPerPage,
            limit: itemsPerPage,
        };

        let sort = getSort(sortBy, sortDesc);

        if (sort.length > 0) {
            params.sort = sort;
        }

        return httpClient.get(endPoint, {
            params,
            paramsSerializer: function (params) {
                return QueryString.stringify(params, {arrayFormat: 'indices'})
            },
        })
    };

    const getItem    = (id)         => httpClient.get(endPoint + '/' + id);
    const deleteItem = (id)         => httpClient.delete(endPoint + '/' + id);
    const createItem = (fields)     => httpClient.post(endPoint, fields);
    const updateItem = (id, fields) => httpClient.patch(endPoint + '/' + id, fields);

    return {
        getAll,
        getItems,
        getItem,
        createItem,
        deleteItem,
        updateItem,
    }
}

export default crudFactory