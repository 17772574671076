<template>
  <v-toolbar class="mb-3 pt-3">
    <v-row>
      <v-col
          cols="12"
          sm="6"
          md="4"
      >
        <v-menu
            v-model="showDateFrom"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateFrom"
                label="Дата с"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="dateFrom"
              @input="showDateFrom = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
          cols="12"
          sm="6"
          md="4"
      >
        <v-menu
            v-model="showDateTo"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateTo"
                label="Дата по"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="dateTo"
              @input="showDateTo = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
          cols="12"
          sm="6"
          md="4"
      >
        <v-btn @click="clear">Сбросить</v-btn>
      </v-col>
    </v-row>
  </v-toolbar>
</template>

<script>
export default {
  name: 'DateFilters',
  data: () => ({
    showDateFrom: false,
    showDateTo: false,
    dateFrom: null,
    dateTo: null,
  }),
  methods: {
    clear() {
      this.dateFrom = null
      this.dateTo = null
    },
  },
  watch: {
    dateFrom: function () {
      this.$emit('update', {from: this.dateFrom, to: this.dateTo})
    },
    dateTo: function () {
      this.$emit('update', {from: this.dateFrom, to: this.dateTo})
    },
  },
}
</script>